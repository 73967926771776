.wizard {

  @include themify($themes) {
    background-color: themed('colorBackground');
  }

  .wizard__form {
    max-width: 800px;
    width: 100%;
    margin-top: 50px;
    margin-bottom: 100px;
    padding: 0 25px;

    .wizard__toolbar {
      margin-top: 40px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.wizard__steps {
  display: flex;
}

.wizard__step {
  width: 100%;
  text-align: center;
  height: 55px;
  text-transform: uppercase;
  display: flex;
  transition: background 0.3s;

  @include themify($themes) {
    border: 1px solid themed('colorBorder');
    background: themed('colorHover');
  }

  p {
    font-weight: 700;
    margin: auto;
    font-size: 14px;
    transition: all 0.3s;
  }

  &.wizard__step--active {
    background: $color-blue;
    border-color: $color-blue;

    p {
      color: #ffffff;
    }
  }
}

.wizard__step-disabled {
  cursor: initial !important;
}

@media only screen and (max-width: 600px) {
  .wizard__step {

    p {
      font-size: 13px;
    }
  }

}

.wizard__form-wrapper {
  display: flex;
  justify-content: center;
}

.wizard__title {
  text-align: center;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
  font-weight: 500;
}

.wizard__description {
  color: $color-additional;
  margin: 0;
  max-width: 410px;
}

.wizard__section-title {
  display: inline;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  font-weight: 500;
}

.wizard__form-card {
  padding-bottom: 0px;
  padding-bottom: 0px;
  display: inline-flex;
  margin-bottom: 10px;
}

.wizard__form-card-body {
  padding-bottom: 0px;
  background-color: #efefef !important;
}

.wizard__cards-first-container {
  margin-bottom: 20px;
  width: 100%;
}

.wizard__cards-container {
  margin-bottom: 20px;
  width: 100%;
  border-top: 1px solid;
  padding-top: 20px;
}

.wizard__card-buttons {
  margin-top: 20px;
}

.wizard__contract-text {
  text-align: justify;
  margin-top: 10px;
  display: block;

  @include themify($themes) {
    color: themed('colorText');
  }
}

.wizard__contract-signature {
  text-align: justify;
  margin-top: 10px;
  display: block;
  text-transform: uppercase;
  font-weight: bold;

  @include themify($themes) {
    color: themed('colorText');
  }
}

.wizard__date-title {
  width: 100%;
  text-align: center;
  margin-bottom: 40px;
  margin-top: -10px;
}

.checkbox-btn--wizard__checkbox-margin-left {
  margin-left: 15px;
}
