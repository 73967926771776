.circular-progress {
  color: #131457 !important;
  position: fixed !important;
  top: 45% !important;
  z-index: 9999;
  left: 49% !important;
}

.MuiDialogTitle-root {
  color: #131457 !important;
}
